import React, { useEffect } from 'react';

import { Crumb, useBreadcrumbs } from 'contexts/Breadcrumbs/Context';
import Head from 'next/head';

export interface BaseLayoutProps {
  children: React.ReactNode;
  className?: string;
  crumbs: Crumb[];
  description: string;
  title: string;
}

export function BaseLayout({ title, children, description, crumbs, className }: BaseLayoutProps) {
  const { setCrumbs } = useBreadcrumbs();

  useEffect(() => {
    setCrumbs(crumbs);
  }, [crumbs]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>
      <main className={className} id="content" tabIndex={-1} style={{ flexGrow: 1 }}>
        {children}
      </main>
    </>
  );
}
