import { getContentGroupCompletion } from './getContentGroupCompletions';

export function getStudentModuleCompletions(userFlow, unit) {
  if (!userFlow) return [];

  const allContentGroups = [...userFlow.userFlowSteps].map((userFlowStep) => {
    return {
      ...userFlowStep.contentGroup,
      userFlowStep,
    };
  });

  const groupings = allContentGroups.map((contentGroup) => contentGroup.groupingContentGroup);

  return Array.from(new Set<number>(groupings.map((grouping) => grouping?.id)))
    .filter((id) => id)
    .sort((a, b) => {
      return a - b;
    })
    .map((id) => {
      const thisGrouping = groupings.find((grouping) => grouping?.id === id);
      const descriptionGroup = thisGrouping?.contentGroups?.find((contentGroup) => contentGroup.type === 'PAGE')?.contentPages || [];
      var cartoonStep = userFlow.userFlowSteps.find(
        (r) => r.contentGroup.groupingContentGroup.id === id && r.contentGroup.type === 'CARTOON',
      );
      var activitySteps = userFlow.userFlowSteps.filter((r) => {
        const isActivity = r.contentGroup.groupingContentGroup.id === id && r.contentGroup.type === 'ACTIVITY';
        const hasAccess = Array.isArray(unit?.get)
          ? unit?.get.map((unitMode) => unitMode.contentGroup.id).includes(r.contentGroup.id)
          : false;

        return isActivity && hasAccess;
      });

      const completedActivityGroups = activitySteps.filter(({ percentageCompleted }) => percentageCompleted === 1);
      const cartoonCompletionPercentage = cartoonStep?.percentageCompleted || 0;
      const cartoonCompletionMaximumPercentage = activitySteps.length === 0 ? 1 : 0.9;

      const lessonCompletionPercentage =
        cartoonCompletionPercentage * cartoonCompletionMaximumPercentage +
        (completedActivityGroups.length / activitySteps.length || 0) * 0.1;

      let userStatus = 'AVAILABLE';
      if (lessonCompletionPercentage > 0) userStatus = 'IN_PROGRESS';
      if (lessonCompletionPercentage === 1) userStatus = 'COMPLETED';

      return {
        ...thisGrouping,
        isCartoonCompleted: cartoonStep?.percentageCompleted === 1,
        activityCompletionPercentage: completedActivityGroups.length / activitySteps.length,
        lessonCompletionPercentage,
        cartoon: cartoonStep,
        lastViewedPage: cartoonStep.lastViewedContentPage,
        cartoonCompletionPercentage,
        image: cartoonStep?.contentGroup.iconAsset,
        activities: activitySteps,
        userStatus,
        descriptionGroup,
      };
    });
}
