import { useEffect, useState } from 'react';

import { useAccessToken, usePermissions } from 'contexts/Authorization/Context';
import { getHomeRoute } from 'utils/functions/getHomeRoute';

export function useHomeRoute() {
  const [homeRoute, setHomeRoute] = useState('/');
  const token = useAccessToken();
  const permissions = usePermissions();

  useEffect(() => {
    setHomeRoute(getHomeRoute(token, permissions));
  }, [token]);

  return homeRoute;
}
