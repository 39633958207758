import { DocumentNode, LazyQueryHookOptions, MutationHookOptions, QueryHookOptions, QueryResult } from '@apollo/client';
import { useAccessToken, useTempToken } from 'contexts/Authorization/Context';
import { Endpoint } from 'graphql/client';
// import { useHandleLoadingState } from '../context/LoadingContext';
// import { useHandleErrorState } from '../context/ErrorContext';

export interface IOptions {
  accessToken?: string;
  anon?: boolean;
  endpoint?: Endpoint;
  localError?: boolean;
  localLoading?: boolean;
}

export function useApolloWrapper<T extends LazyQueryHookOptions | MutationHookOptions | QueryHookOptions, R extends QueryResult | any>(
  func: (apolloQuery: DocumentNode, apolloOptions?: T) => R,
  query: DocumentNode,
  options?: IOptions & (LazyQueryHookOptions | MutationHookOptions | QueryHookOptions),
) {
  const { accessToken, anon, endpoint, localError, localLoading, ...apolloOptions } = options || {};

  const storedToken = useAccessToken();
  const storedTempToken = useTempToken();

  apolloOptions.context = {
    ...apolloOptions.context,
    headers: {
      ...apolloOptions.context?.headers,
      custom_build_guid: process.env.REACT_APP_PROJECT_ID,
      project_id: process.env.REACT_APP_PROJECT_ID,
      ...(() => {
        const authorization = accessToken || storedToken || storedTempToken;
        if (authorization && !anon) {
          return { authorization };
        }
        return {};
      })(),
    },
  };

  const value = func(query, apolloOptions as T);

  // useHandleLoadingState(query.loading);
  // useHandleErrorState(query.error);

  return value;
}
