import React, { ReactNode } from 'react';

import { useFormikContext } from 'formik';
import pluralize from 'pluralize';

import { useFormId } from 'components/Shared/Forms/Form/Form';

function FormErrors() {
  const { errors, submitCount } = useFormikContext();
  const errorArray = Object.entries(errors);
  const formId = useFormId();

  if (errorArray.length === 0 || submitCount === 0) return null;

  return (
    <div className="h-sr-only" role="alert">
      <h4>
        There {pluralize('are', errorArray.length)} {errorArray.length} {pluralize('errors', errorArray.length)} in this form.
      </h4>
      <ul>
        {errorArray.map(([inputId, errorMessage]) => {
          return (
            <li key={inputId}>
              <a href={`#${formId}_${inputId}`} id={`#${formId}_${inputId}_error`}>
                {errorMessage as ReactNode}.
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export { FormErrors };
