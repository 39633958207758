import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useAccessToken, useGroup, usePermissions } from 'contexts/Authorization/Context';
import { useNotifications } from 'contexts/Notifications/Context';
import { formatDistance } from 'date-fns';
import { getRole } from 'utils/functions/getRoleType';

import { Button } from 'components/Shared/Inputs/Button';
import { Link } from 'components/Shared/UI/Link';
import { Popover } from 'components/Shared/UI/Popover';

export function NotificationPopover() {
  const { hasUnreadNotifications } = useNotifications();

  const group = useGroup();
  const roleType = getRole(usePermissions(), group.id);

  const NotificationMap = {
    Teacher: ['CommentPost', 'SubComment', 'RelatePost', 'RelateComment', 'SurveyTeacher'],
    Student: ['UnitModeAssigned', 'LicencedUserFlow', 'ModuleCompleted', 'UserFlowStepTrackMarked'],
  };

  const [isHydrated, setIsHydrated] = useState<boolean>(false);
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  const getLink = (metadata: any) => {
    switch (metadata.type) {
      case 'SurveyTeacher':
        return `/teacher/survey?name=${metadata.userFlowStepUrl}`;
      case 'UnitModeAssigned':
        return `/student/assignedActivity?cg=${metadata?.contentGroupId}`;
      case 'LicencedUserFlow':
      case 'ModuleCompleted':
        return `/student`;
      case 'CommentPost':
      case 'SubComment':
      case 'RelatePost':
      case 'RelateComment':
        return `/teacher/discussion-board?postId=${metadata?.postId}`;
      default:
        return '/';
    }
  };
  const closeRef = useRef<() => void>();
  const { notifications, isLoading, setNotificationsAllRead, setNotificationsAllOld } = useNotifications();

  useEffect(() => {
    return setNotificationsAllOld;
  }, []);

  const filterNotification = (r: any) => {
    return NotificationMap[roleType]?.includes(r.metadata.type);
  };
  return (
    <Popover
      className="c-notification"
      onOpen={setNotificationsAllRead}
      cta={({ handleClick }) => (
        <Button icon="notification" filled round hasAttention={hasUnreadNotifications} onClick={handleClick} title="Notifications" />
      )}
      horizontalAlign="right"
      withCloseButton={false}
    >
      {({ handleClick }) => {
        closeRef.current = handleClick;

        return (
          <div className="c-notification__menu" style={{ maxWidth: '100%' }}>
            <div className="h-with-padding">
              <h1 className="h3">Notifications</h1>
            </div>

            {isHydrated && (
              <ul className="l-full-width ">
                {isLoading && (
                  <li
                    className={cx(
                      'l-position--relative',
                      'h-with-padding',
                      'h-background--color-grey-300',
                      'h-section-item-background-extend--both',
                      'l-flex',
                    )}
                  >
                    <p className="c-typography--semi-bold">Loading notifications.</p>
                  </li>
                )}
                {!isLoading && notifications.filter(filterNotification).length === 0 && (
                  <li
                    className={cx(
                      'l-position--relative',
                      'h-with-padding',
                      'h-background--color-grey-300',
                      'h-section-item-background-extend--both',
                      'l-flex',
                    )}
                  >
                    <p className="c-typography--semi-bold">There are no notifications yet.</p>
                  </li>
                )}
                {notifications.filter(filterNotification).map((notification, index) => {
                  const isEven = index % 2 === 0;

                  return (
                    <li
                      style={{
                        paddingLeft: '2rem',
                      }}
                      key={notification.id}
                      className={cx(
                        'l-position--relative',
                        'h-with-padding',

                        'h-section-item-background-extend--both',
                        'l-flex',
                        {
                          'h-background--color-white': isEven,
                          'h-background--color-grey-300': !isEven,
                        },
                      )}
                    >
                      {notification.isNew && (
                        <span
                          style={{
                            top: '50%',
                            left: '1rem',
                            transform: 'translate(0, -50%)',
                          }}
                          className="l-position--absolute ring-container ring-container--notification"
                        >
                          <span className="ringring ringring--green" />
                          <span className="circle circle--green" />
                        </span>
                      )}
                      <div>
                        {getLink(notification.metadata) === '/' && (
                          <p
                            className={cx('c-typography--semi-bold', {
                              'c-typography--color-secondary': notification.isNew,
                              'c-typography--color-grey-700': !notification.isNew,
                            })}
                          >
                            {' '}
                            {notification.message}
                          </p>
                        )}
                        {getLink(notification.metadata) !== '/' && (
                          <Link
                            className={cx('c-Link--underlined', 'c-typography--semi-bold', {
                              'c-typography--color-secondary': notification.isNew,
                              'c-typography--color-grey-700': !notification.isNew,
                            })}
                            href={getLink(notification.metadata)}
                          >
                            {notification.message}
                          </Link>
                        )}
                        <p className="c-typography--fontsize-small c-typography--color-grey-700">
                          {formatDistance(notification.createdDate, new Date())}
                        </p>
                      </div>
                    </li>
                  );
                })}
                {roleType !== 'Student' ? (
                  <li className="c-popover__settings__container">
                    <Link href="/user/notification-settings">
                      <p className="popover__settings__text">Settings</p>
                    </Link>
                  </li>
                ) : null}
              </ul>
            )}
          </div>
        );
      }}
    </Popover>
  );
}
