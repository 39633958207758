import { getContentGroupCompletion } from './getContentGroupCompletions';

export function getLessonsFromUserFlow(userFlow) {
  if (!userFlow) return [];
  const allContentGroups = [...userFlow.userFlowSteps].map((userFlowStep) => userFlowStep.contentGroup);

  const groupings = allContentGroups.map((contentGroup) => contentGroup.groupingContentGroup);

  return Array.from(new Set<number>(groupings.map((grouping) => grouping?.id)))
    .filter((id) => id)
    .sort((a, b) => a - b)
    .map((id) => {
      const thisGrouping = groupings.find((grouping) => grouping?.id === id);

      const contentGroups = allContentGroups
        .filter(({ groupingContentGroup }) => {
          return groupingContentGroup?.id === thisGrouping.id;
        })
        .map((contentGroup) => {
          return {
            ...contentGroup,
            userFlowStep: userFlow.userFlowSteps.find((userFlowStep) => userFlowStep.contentGroup.id === contentGroup.id),
          };
        });

      return contentGroups;
    });
}

export function getModuleCompletions(userFlow, unit) {
  if (!userFlow) return [];

  const allContentGroups = [...userFlow.userFlowSteps].map((userFlowStep) => {
    return {
      ...userFlowStep.contentGroup,
      userFlowStep,
    };
  });

  const groupings = allContentGroups.map((contentGroup) => contentGroup.groupingContentGroup);

  return Array.from(new Set<number>(groupings.map((grouping) => grouping?.id)))
    .filter((id) => id)
    .sort((a, b) => {
      return a - b;
    })
    .map((id) => {
      const thisGrouping = groupings.find((grouping) => grouping?.id === id);
      const contentGroups = allContentGroups
        .filter(({ groupingContentGroup }) => {
          return groupingContentGroup?.id === thisGrouping.id;
        })
        .map((contentGroup) => {
          const userFlowStep = userFlow.userFlowSteps.find((step) => step.contentGroup.id === contentGroup.id);
          const lastViewedPage = userFlowStep.lastViewedContentPage;
          const mode = (unit?.get || []).find((unitMode) => unitMode.contentGroup.id === contentGroup.id);
          return getContentGroupCompletion(contentGroup, mode, lastViewedPage, userFlowStep);
        });

      const cartoonGroup = contentGroups.find((contentGroup) => contentGroup.type === 'CARTOON');
      const descriptionGroup = thisGrouping?.contentGroups?.find((contentGroup) => contentGroup.type === 'PAGE')?.contentPages || [];

      const activityGroups = contentGroups.filter((contentGroup) => {
        const isActivity = contentGroup.type === 'ACTIVITY';
        const hasAccess = Array.isArray(unit?.get)
          ? unit?.get.map((unitMode) => unitMode.contentGroup.id).includes(contentGroup.id)
          : false;

        return isActivity && hasAccess;
      });

      const completedActivityGroups = activityGroups.filter(({ completionPercentage }) => completionPercentage === 1);

      const cartoonCompletionPercentage = cartoonGroup?.completionPercentage || 0;

      const cartoonCompletionMaximumPercentage = activityGroups.length === 0 ? 1 : 0.9;

      const lessonCompletionPercentage =
        cartoonCompletionPercentage * cartoonCompletionMaximumPercentage +
        (completedActivityGroups.length / activityGroups.length || 0) * 0.1;

      const activityCompletionPercentage = (([per, count]) => {
        return per / count;
      })(
        activityGroups.reduce(
          (memo, contentGroup) => {
            return [
              memo[0] + contentGroup.completionPercentage * contentGroup.contentPages.length,
              memo[1] + contentGroup.contentPages.length,
            ];
          },
          [0, 0],
        ),
      );

      let userStatus = 'AVAILABLE';
      if (lessonCompletionPercentage > 0) userStatus = 'IN_PROGRESS';
      if (lessonCompletionPercentage === 1) userStatus = 'COMPLETED';

      return {
        sort: contentGroups.find((contentGroup) => contentGroup.type === 'CARTOON')?.sort,
        ...thisGrouping,
        contentGroups,
        lessonCompletionPercentage,
        cartoon: cartoonGroup,
        cartoonCompletionPercentage,
        activities: activityGroups,
        activityCompletionPercentage,
        userStatus,
        descriptionGroup,
      };
    });
}
