import React from 'react';

import cx from 'classnames';
import { ColorType } from 'consts/colors';

import { IconTypes } from 'components/Shared/UI/Icons/Icons';

export interface IconProps {
  backgroundColor?: ColorType;
  borderColor?: ColorType;
  className?: string;
  color?: 'primary' | 'secondary' | 'tertiary' | ColorType;
  id: IconTypes;
  rotate?: '0' | '90' | '180' | '270';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | 'full' | number;
  title?: string;
}

/**
 * Used to insert an SVG icon into other components.
 */
const Icon: React.FC<IconProps> = ({ className, id, size = 'lg', color = 'white', rotate = '0', borderColor, backgroundColor, title }) => {
  return (
    <div
      title={title}
      style={typeof size === 'number' ? { width: `${size}rem`, height: `${size}rem` } : undefined}
      className={cx(
        'l-flex',
        'l-flex--center',
        backgroundColor && `c-icon__background h-background--color-${backgroundColor}`,
        borderColor && `c-icon__border c-icon__border--color-${borderColor}`,

        `c-icon--size-${size}`,
        className,
      )}
    >
      <svg aria-hidden="true" className={cx('c-icon', `c-icon--${color}`, 'c-icon--size-full', `c-icon--rotate-${rotate}`)}>
        <use xlinkHref={`#${id}`} />
      </svg>
    </div>
  );
};

export { Icon };
