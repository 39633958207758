import { usePermissions } from 'contexts/Authorization/Context';
import decode from 'jwt-decode';

const ROLES = Object.freeze({
  admin: 'Admin',
  public: 'Public',
  student: 'Student',
  teacher: 'Teacher',
});

const ROLE_MAP = Object.freeze({
  NONE: ROLES.teacher,
  MANAGE_USERS: ROLES.teacher,
  EDIT: ROLES.teacher,
  COLLABORATE: ROLES.teacher,
  READ: ROLES.student,
});

const ALL_ROLE_TYPES = ['Admin', 'Public', 'Student', 'Teacher', 'PendingTeacher'] as const;

export type RoleType = (typeof ALL_ROLE_TYPES)[number];

export function isUserAdmin(permissions: any): boolean {
  if (!permissions) {
    return false;
  }
  const decodedPermissions = permissions.filter(({ id }) => id !== process.env.REACT_APP_PROJECT_DEFAULT_GROUP);
  return decodedPermissions.find((r) => r.t === 'PROJECT');
}

export function getRole(permissions: any, groupId: string = undefined): RoleType {
  if (!permissions) {
    return null;
  }

  if (permissions.length === 1 && Number(permissions[0].id) === Number(process.env.REACT_APP_PROJECT_DEFAULT_GROUP)) {
    return 'PendingTeacher';
  }

  const decodedPermissions = permissions.filter(({ id }) => id !== process.env.REACT_APP_PROJECT_DEFAULT_GROUP);

  if (decodedPermissions.length === 0) {
    return 'Public';
  }

  if (decodedPermissions[0].t === 'GROUP') {
    if (groupId) {
      return ROLE_MAP[decodedPermissions.find((c) => Number(c.id) === Number(groupId))?.p] as RoleType;
    }
    return ROLE_MAP[decodedPermissions[0]?.p] as RoleType;
  }

  if (decodedPermissions[0].t === 'PROJECT') {
    return ROLE_MAP[decodedPermissions[0]?.p] as RoleType;
  }

  if (decodedPermissions[0].t === 1) {
    return ROLE_MAP[decodedPermissions.find((c) => c.id === process.env.REACT_APP_PROJECT_ID)?.p] as RoleType;
  }

  return 'Public';
}
