export const avatarImageMap = {
  1: 'Clare',
  2: 'Dave',
  3: 'Emma',
  4: 'Jane',
  5: 'Jimmy',
  6: 'Kirsten',
  7: 'Mai',
  8: 'Mike',
  9: 'Rachel',
  10: 'Tom',
  11: 'Dan',
  12: 'Malika',
  13: 'Peter',
};
