import { getTempTokenQuery } from 'contexts/Authorization/Context.queries';
import { Client } from 'graphql/client';
import { CookiesProvider, Cookies, useCookies } from 'react-cookie';

export async function getTempToken() {
  const client = Client('gelada');

  const { data } = await client.query({
    query: getTempTokenQuery,
  });

  const { getTemporarySession: tempToken } = data;
  new Cookies().set('tempToken', tempToken);

  return tempToken;
}
