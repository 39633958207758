export interface CustomFieldType {
  name: string;
  value: string;
}

export function addUserToEmailSubscription(emailAddress: string, name: string, customFields: CustomFieldType) {
  const Http = new XMLHttpRequest();
  const url = 'https://00y089pbdi.execute-api.ap-southeast-2.amazonaws.com/default/email-subscriber';

  Http.open('POST', url);

  Http.send(
    JSON.stringify({
      SendApiKey: process.env.REACT_APP_CREATE_SEND_API_KEY,
      SendListId: process.env.REACT_APP_CREATE_SEND_LIST_ID,
      emailAddress,
      name,
      customFields,
    }),
  );

  // return Http.responseText === 'true';

  return true;
}
