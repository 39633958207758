export function isTrialExpired(login) {
  const {
    user: { customFields },
  } = login;

  if (customFields) {
    const endTrialPeriod = customFields.find((r) => r.customFieldId === 28)?.dateTime;
    if (endTrialPeriod) {
      const endTrial = new Date(endTrialPeriod);
      if (endTrial.getTime() < new Date().getTime()) {
        return false;
      }
    }
  }
  return true;
}
