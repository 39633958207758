import React from 'react';

import { useBreadcrumbs } from 'contexts/Breadcrumbs/Context';
import { useRouter } from 'next/router';

import { Link } from 'components/Shared/UI/Link';

function Breadcrumbs() {
  const { asPath, route } = useRouter();
  const manualCrumbs = useBreadcrumbs().crumbs;

  // the first part is an empty string
  const pages = asPath.split('/').splice(1);
  const routes = route.split('/').splice(1);

  const crumbs = pages.map((location, index) => {
    let href = '';
    let as = '';
    for (let i = 0; i <= index; i += 1) {
      href = `${href}/${routes[i]}`;
      as = `${as}/${pages[i]}`;
    }

    return {
      title: location.replace(/-|\?.*|#.*/g, ' '),
      href,
      as,
    };
  });

  const excludedRoutes = ['/', '/dashboard'];

  const excludedTest = crumbs.filter((crumb) => {
    const { href } = crumb;
    return excludedRoutes.includes(href);
  });

  if (excludedTest.length !== 0) {
    return null;
  }

  return (
    <nav aria-label="Breadcrumbs" className="h-background--color-brand-secondary h-with-small-padding c-breadcrumbs">
      <ul className="l-flex l-flex--justify-end l-flex--wrap l-container h-no-padding l-container--narrow text-right">
        {manualCrumbs.map((crumb, index) => {
          return (
            <li key={crumb.title}>
              <Link href={crumb.route}>{crumb.title}</Link>
              {index < manualCrumbs.length - 1 && <span aria-hidden>&nbsp;/&nbsp;</span>}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export { Breadcrumbs };
