import { useRef, useEffect } from 'react';

export function useClickOutside(run, func) {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (run && ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, run]);

  return ref;
}
