import { ColorType } from 'consts/colors';

const sizeMap = {
  sm: '2px',
  md: '5px',
  lg: '10px',
};

type SizeType = 'sm' | 'md' | 'lg';

export interface HRPropType {
  color: ColorType;
  size: SizeType;
}

export function HR({ color, size = 'md' }) {
  return (
    <hr
      style={{ height: sizeMap[size], border: 'none' }}
      className={`h-section-item-background-extend--both h-background--color-${color}`}
    />
  );
}
