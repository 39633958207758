import React, { ReactNode } from 'react';

import cx from 'classnames';
import { Field, useField, FieldAttributes, useFormikContext } from 'formik';

import { useFormId } from 'components/Shared/Forms/Form/Form';
import { FormInputError } from 'components/Shared/Forms/FormInputError';

export interface InputFieldProps extends FieldAttributes<any> {
  altBackground?: boolean;
  children?: ReactNode;
  className?: string;
  hasBorder?: boolean;
  hideError?: boolean;
  inputClassName?: string;
  isDisabled?: boolean;
  label: ReactNode;
  name: string;
  orientation?: 'column' | 'row';
  required?: boolean;
  rounded?: boolean;
}

function InputField({
  altBackground,
  children,
  name,
  className,
  label,
  required,
  hideError,
  isDisabled,
  type,
  orientation = 'column',
  rounded,
  inputClassName,
  hasBorder,
  ...others
}: InputFieldProps) {
  const { error, touched, value } = useField(name)[1];
  const { submitCount } = useFormikContext();
  const formId = useFormId();
  const showError = error && (touched || submitCount !== 0);

  const isSelectable = type === 'checkbox' || type === 'radio';

  let checked;

  if (type === 'checkbox' && Array.isArray(value)) {
    checked = value.includes(String(others.value));
  }

  if (isSelectable && typeof value === 'string') {
    checked = value === others.value;
  }

  if (isSelectable && typeof value === 'boolean') {
    checked = value;
  }

  return (
    <div
      className={cx(`c-input-field l-flex__item--${orientation}`, 'u-foreground', className, {
        'l-full-width': orientation === 'column',
      })}
    >
      <div className="l-flex">
        <Field
          placeholder={`${label}${required ? ' *' : ''}`}
          type={type}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...others}
          // props that should not be overridden
          id={`${formId}_${name}${isSelectable ? `_${others.value}` : ''}`}
          aria-describedby={`${formId}_${name}_description`}
          name={name}
          aria-required={required}
          checked={checked}
          className={cx('c-input', 'l-flex__item--row', inputClassName, {
            'c-input--has-error': showError,
            'c-input--alt-background': altBackground,
            'h-rounded-border': rounded,
            'h-border h-border--color-brand': hasBorder,
          })}
          data-value={value}
          disabled={isDisabled}
        >
          {children}
        </Field>
        <label
          className={`l-flex__item--row  ${isSelectable ? '' : 'h-sr-only'}`}
          htmlFor={`${formId}_${name}${isSelectable ? `_${others.value}` : ''}`}
        >
          {label} {required && <span aria-hidden>* </span>}
        </label>
      </div>
      {hideError || <FormInputError name={name} />}
    </div>
  );
}

export { InputField };
