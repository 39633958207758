import { useEffect, useRef, useState } from 'react';

import { ApolloProvider } from '@apollo/client';
import { AuthorizationProvider } from 'contexts/Authorization/Context';
import { BreadcrumbsProvider } from 'contexts/Breadcrumbs/Context';
import { NotificationProvider } from 'contexts/Notifications/Context';
import { Client } from 'graphql/client';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { getItem } from 'utils/functions/localStorage';

import { Footer } from 'components/Shared/UI/Footers/Footer';
import { Header } from 'components/Shared/UI/Headers/Header';
import { Icons } from 'components/Shared/UI/Icons/Icons';

import '../styles/index.css';
import { PageView } from 'components/Metrics/GA/GA';

const LogRocket = require('logrocket');

function MyApp({ Component, pageProps }) {
  const scrollRef = useRef<HTMLDivElement>();
  const router = useRouter();

  const user = getItem('user');
  if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
    LogRocket.init('sfzpuh/ourfutres');
    if (user) {
      LogRocket.identify(user.id, {
        name: `${user.firstname} ${user.lastname}`,
        email: user.credential.email,
      });
    }
  }

  const [isHydrated, setIsHydrated] = useState<boolean>(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);
  useEffect(() => {
    const user = getItem('user');

    const handleRouteChange = (url) => {
      PageView(url, user?.id);
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    window.onclick = (e) => {
      const a = e.target.closest('a');
      if (!a) return;
      if (a.href.indexOf(window.location.origin) !== 0) return;
      if (a.target === '_blank') return;
      e.preventDefault();

      const as = a.href.replace(window.location.origin, '');

      if (a.classList.contains('NextLink')) return;

      let href = as;
      if (href.match(/module\/.*\/.*/)) href = '/module/[stepUrl]/[pageUrl]';

      Router.push(href, as);
    };

    Router.events.on('routeChangeComplete', (url) => {
      const { Kanzi_Manual_Commands: kanziManualCommands } = window as any;

      if (kanziManualCommands) {
        kanziManualCommands?.Pause();
        kanziManualCommands?.ResetKeepLanguage();
      }

      if (url.includes('/cartoon/')) return;
      scrollRef.current?.scrollIntoView?.();
    });

    // Select the node that will be observed for mutations
    const targetNode = document.getElementById('__next');

    const config = { attributes: true, childList: true, subtree: true };
    const defaultLanguage = 'en';

    let timeOut;

    const callback = (mutationsList) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutationsList) {
        if (mutation.type !== 'childList') {
          return;
        }

        const tempWindow: any = window;
        const { Kanzi_Manual_Commands: kanziManualCommands } = tempWindow;

        if (!kanziManualCommands) {
          return;
        }

        if (kanziManualCommands.CurrentLanguage() === defaultLanguage || kanziManualCommands.IsPlaying()) {
          return;
        }

        if (timeOut) {
          tempWindow.clearTimeout(timeOut);
        }

        timeOut = setTimeout(function () {
          const currentLanguage = kanziManualCommands.CurrentLanguage();
          kanziManualCommands.Reset();
          kanziManualCommands.Translate(defaultLanguage, currentLanguage);
        }, 1500);
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, config);
  }, []);

  return (
    <>
      {isHydrated && (
        <>
          <Head>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          </Head>
          <div
            style={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              overflowX: 'hidden',
            }}
          >
            <a href="#content" className="h-skip-main" title="Skip main" />
            <div ref={scrollRef} />
            <AuthorizationProvider>
              <ApolloProvider client={Client('ekardo')}>
                <BreadcrumbsProvider>
                  <NotificationProvider>
                    <Header />
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...pageProps} />
                    <Footer />
                    <Icons />
                  </NotificationProvider>
                </BreadcrumbsProvider>
              </ApolloProvider>
            </AuthorizationProvider>
          </div>
        </>
      )}
    </>
  );
}

export default MyApp;
