function setWithExpiry(setter: (string) => void, value: unknown, expiry?: Date) {
  const temp = JSON.stringify({ value, expiry: expiry && expiry.getTime() });
  setter(temp);
  return temp;
}

function getWithExpiry(reset: () => void, itemStr: string) {
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const { value, expiry } = JSON.parse(itemStr);
  const now = new Date();

  if (!expiry) return value;

  // compare the expiry time of the item with the current time
  if (now.getTime() > expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    reset();
    return null;
  }
  return value;
}

export { setWithExpiry, getWithExpiry };
