import React from 'react';

export interface LogoProps {
  className?: string;
}

export function Logo({ className }: LogoProps) {
  return (
    <div
      className={className}
      style={{
        minWidth: '100px',
        width: '100%',
        maxHeight: '3.5rem',
      }}
    >
      <img alt="our futures home" src="https://health4life-assets.s3-ap-southeast-2.amazonaws.com/ourfutures-logo.svg" />
    </div>
  );
}
