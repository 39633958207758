import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { useGroup, usePermissions } from 'contexts/Authorization/Context';
import useDeviceDetect from 'contexts/UseDeviceDetect/UseDeviceDetect';
import { teacherNavList } from 'layouts/Teacher';
import { getRole } from 'utils/functions/getRoleType';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import { Button } from 'components/Shared/Inputs/Button';
import { Logo } from 'components/Shared/UI/Icon';
import { Link } from 'components/Shared/UI/Link';

import { UserSection } from './UserSection';
import { NotificationPopover } from './UserSection/NotificationPopover';

export function Header() {
  const { isTouchDevice } = useDeviceDetect();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const group = useGroup();
  const roleType = getRole(usePermissions(), group.id);

  const { isLargeTablet, isDesktop } = useWindowSize();

  useEffect(() => {
    isMobileNavOpen && !isTouchDevice ? () => setIsMobileNavOpen(false) : null;
  });

  return (
    <>
      <header className="c-header l-container l-grid l-grid--spaced h-background--color-white">
        <div className="l-flex l-flex--center l-full-width">
          <Link href="/" aria-label="Home" className="l-full-width">
            <Logo className="c-header__logo" />
          </Link>
        </div>
        <UserSection className="c-header__actions" />
        <div className="c-header__hamburger l-flex l-flex--justify-end l-position--relative">
          <NotificationPopover />
          <div className="c-header__mobile-menu-background" />
          <Button
            id="mobile-menu-opener"
            icon="hamburger"
            filled
            color="secondary"
            eliptical
            aria-label="Open menu"
            onClick={() => setIsMobileNavOpen(true)}
          />
          <div
            className={cx('c-header__mobile-nav  l-flex--column l-flex--align-center  h-scrollable h-background--color-white', {
              'h-hide': !isMobileNavOpen,
              'h-overlay': isMobileNavOpen,
              'l-flex': isMobileNavOpen,
            })}
          >
            <div className="c-header l-container l-grid l-grid--spaced">
              <div className="c-header__logo l-flex l-flex--center l-full-width">
                <Link href="/" aria-label="Home" className="l-full-width" onClick={() => setIsMobileNavOpen(false)}>
                  <Logo />
                </Link>
              </div>
              <div className="c-header__hamburger l-flex l-flex--justify-end l-position--relative">
                <div className="c-header__mobile-menu-background" />
                <Button
                  id="mobile-menu-closer"
                  icon="cross"
                  filled
                  color="secondary"
                  eliptical
                  aria-label="Close menu"
                  onClick={() => setIsMobileNavOpen(false)}
                />
              </div>
            </div>

            {roleType === 'Teacher' && (
              <ul className="h-with-margin-bottom h-with-text-align-center" role="navigation">
                {teacherNavList?.map((item) => {
                  const isStandAloneLink = item?.pages?.length === 0;
                  if (isStandAloneLink) {
                    return (
                      <li key={item.id}>
                        <Link href={item.link}>
                          <span className="h-inline-block-display h5 h-with-small-margin-bottom">{item.label}</span>
                        </Link>
                      </li>
                    );
                  }

                  return (
                    <li key={item.id}>
                      <span className="h-inline-block-display h5 h-with-small-margin-bottom">{item?.label}</span>
                      <ul>
                        {item?.pages?.map((subLink) => (
                          <li key={subLink.id} className="h-with-margin-bottom">
                            <Link href={subLink.link}>{subLink.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            )}

            <UserSection onClick={() => setIsMobileNavOpen(false)} className="c-user-section l-position-v-bottom" />
          </div>
        </div>
      </header>
    </>
  );
}
