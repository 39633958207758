import React from 'react';

import { ButtonLink } from 'components/Shared/Inputs/Button';
import { HR } from 'components/Shared/UI/HR';
import { Link } from 'components/Shared/UI/Link';

export interface FooterProps {
  showMenu?: boolean;
}

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="c-footer h-background--color-white l-full-width l-position--relative">
        <HR color="brand-tertiary" />
        <img className="p-index__thumbpin h-no-top h-with-padding h-mobile__hidden" src="/images/thumbpin.svg" alt="" />
        <div className="c-footer__nav-lists l-container l-responsive-grid">
          <nav className="c-footer__nav-list l-responsive-grid__item--3-col" aria-label="Information">
            <ul className="l-flex l-flex--column">
              <li className="l-flex__item--column registration kanzi-not-translate">
                <Link href="/faq">FAQs</Link>
              </li>
              <li className="l-flex__item--column">
                <Link href="/technical-requirements">Technical requirements</Link>
              </li>
              <li className="l-flex__item--column">
                <Link href="/privacy-policy">Privacy policy</Link>
              </li>
              <li className="l-flex__item--column">
                <Link href="/terms-and-conditions">Terms & conditions</Link>
              </li>
            </ul>
          </nav>
          <ButtonLink className="c-footer__button l-responsive-grid__item--6-col" href="/helplines" filled color="secondary">
            Helplines & support resources
          </ButtonLink>
        </div>
        <div className="l-flex l-flex--mobile-column h-background--color-brand l-full-width">
          <div className="l-container l-flex l-flex--justify-space-between l-flex--mobile-column">
            <div className="c-footer__copywrite l-flex__item--mobile-column">© {currentYear} | OurFutures</div>
            <Link className="c-footer__built l-flex l-flex__item--mobile-column" href="https://www.netfront.com.au" isExternal>
              Built by Netfront
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}
