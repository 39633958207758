import React from 'react';

import { useField, useFormikContext } from 'formik';

import { useFormId } from 'components/Shared/Forms/Form/Form';

export interface FormInputErrorProps {
  name: string;
}

function FormInputError({ name }: FormInputErrorProps) {
  const { error, touched } = useField(name)[1];
  const { submitCount } = useFormikContext();

  const formId = useFormId();

  const showError = error && (touched || submitCount !== 0);

  return (
    <div
      id={`${formId}-${name}-description`}
      className="c-form-input-error h-font-weight--600 h-font-size--small h-with-small-padding l-full-width l-flex l-flex--justify-end"
      aria-live="assertive"
    >
      {showError ? error : ''}
    </div>
  );
}

export { FormInputError };
