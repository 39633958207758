import React from 'react';

import { useIsCurrentRoute } from 'utils/hooks/useIsCurrentRoute';

import { Base, ButtonLinkProps } from './Base';

function ButtonLink(props: ButtonLinkProps) {
  const { href, as } = props;

  const isCurrentPath = useIsCurrentRoute({ href, as });

  return (
    <Base
      component="a"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isActive={isCurrentPath}
    />
  );
}

export { ButtonLink };
