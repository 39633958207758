/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties, ReactNode } from 'react';

import cx from 'classnames';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useIsCurrentRoute } from 'utils/hooks/useIsCurrentRoute';

export interface LinkProps extends NextLinkProps {
  children: ReactNode;
  className?: string;
  isDisabled?: boolean;
  isExternal?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

export function Link({ children, href, as, isDisabled, isExternal, className, ...otherProps }: LinkProps) {
  const isCurrentPath = useIsCurrentRoute({ href, as });

  if (isDisabled)
    return (
      <span className={cx(className, 'c-Link')} {...otherProps}>
        {children}
      </span>
    );
  if (isExternal) {
    return (
      <a
        className={cx(className, 'c-Link')}
        // href should always be a string if the route is external
        // @ts-ignore
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        tabIndex={0}
        {...otherProps}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href} as={as} passHref legacyBehavior>
      {/* href is passed by NextLink */}
      {/* https://github.com/vercel/next.js/issues/5533 */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={cx(className, 'c-Link', 's-NextLink', {
          'c-Link--current': isCurrentPath,
        })}
        aria-current={isCurrentPath ? 'page' : undefined}
        tabIndex={0}
        href="#"
        {...otherProps}
      >
        {children}
      </a>
    </NextLink>
  );
}
