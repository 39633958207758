export function getContentGroupCompletionRefactored(contentGroup,  lastPage, userFlowStep) {
  if (!contentGroup) {
    return {
      completionPercentage: 0,
      lastViewedPage: null,
    };
  }

  return {
    ...contentGroup,
    completionPercentage:userFlowStep.percentageCompleted,
    lastViewedPage: lastPage,
  };
}