import { createContext, useContext, useState, useRef, useEffect } from 'react';

import { gql } from '@apollo/client';
import { useUser } from 'contexts/Authorization/Context';
import { Client } from 'graphql/client';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useMutation } from 'utils/hooks/useMutation';

const GetNotificationsQuery = gql`
  query GetNotifications {
    notification {
      notificationsForConnectedUser(type: INTERNAL_NOTIFICATION, isSeen: false) {
        id
        seen
        type
        guid
        content
        metadata
        sendingDate
        notificationGroup {
          internalUsage
          templateId
        }
      }
    }
  }
`;

const SetAllNotificationsSeen = gql`
  mutation SetAllNotificationsSeenMutation {
    internalNotificationEvent {
      markAllSeen(ipAddress: "", userAgent: "")
    }
  }
`;

const SetNotificationSeen = gql`
  mutation setNotificationSeen($guid: String!, $ipAddress: String!, $userAgent: String!) {
    internalNotificationEvent {
      addSeenEvent(notificationGuid: $guid, ipAddress: $ipAddress, userAgent: $userAgent)
    }
  }
`;

interface INotification {
  content: string;
  createdDate: Date;
  guid?: string;
  id: number;
  isNew: boolean;
  isRead: boolean;
  message: string;
  metadata?: any;
}
interface INotificationContext {
  hasUnreadNotifications: boolean;
  isLoading: boolean;
  notifications: INotification[];
  refetch: () => void;
  reminderNotification: INotification[];
  setNotificationSeen: (guid: string) => void;
  setNotificationsAllOld: () => void;
  setNotificationsAllRead: () => void;
  clear: () => void;
}

const Context = createContext<INotificationContext>({
  hasUnreadNotifications: false,
  reminderNotification: {} as INotification[],
  isLoading: true,
  notifications: [],
  refetch: () => undefined,
  setNotificationsAllOld: () => undefined,
  setNotificationsAllRead: () => undefined,
  setNotificationSeen: () => undefined,
  clear: () => undefined,
});

export function NotificationProvider({ children }) {
  function convertUTCDateToLocalDate(date: Date) {
    const notificationDate = new Date(date);

    const adjustedDate = new Date(notificationDate);
    adjustedDate.setHours(adjustedDate.getHours() + 11);

    const newDate = new Date(adjustedDate.getTime() + adjustedDate.getTimezoneOffset() * 60 * 1000);

    const offset = notificationDate.getTimezoneOffset() / 60;
    const hours = notificationDate.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  const howlerClient = useRef(Client('howler'));
  const { id } = useUser();

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [reminderNotification, setReminderNotification] = useState<INotification[]>();

  const replacePlaceholders = (message: string, metadata: any) => {
    return message.replaceAll('[Firstname]', metadata?.firstName);
  };

  useEffect(() => {
    if (id) {
      executeGetNotification();
    }
  }, [id]);

  const [executeGetNotification, { loading: notificationsLoading }] = useLazyQuery(GetNotificationsQuery, {
    client: howlerClient.current,
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const filteredNotification = data.notification.notificationsForConnectedUser.filter(
        (notification) =>
          notification.notificationGroup.templateId === process.env.REACT_APP_TEMPLATE_MODULE_COMPLETED ||
          !notification.notificationGroup.internalUsage ||
          notification.notificationGroup.internalUsage?.find((iu) => iu !== 'REMINDER'),
      );

      const reminderNotification = data.notification.notificationsForConnectedUser.filter(
        (notification) => notification.notificationGroup.internalUsage?.find((iu) => iu === 'REMINDER') && !notification.seen,
      );

      setReminderNotification(reminderNotification);

      setNotifications((currentNotifications) => {
        return [
          ...currentNotifications,
          ...filteredNotification.map((notification) => {
            const metadata = JSON.parse(notification.metadata);
            const message = replacePlaceholders(notification.content, metadata);

            return {
              id: notification.id,
              isRead: notification.seen,
              message,
              metadata,
              isNew: !notification.seen,
              createdDate: convertUTCDateToLocalDate(notification.sendingDate),
            };
          }),
        ];
      });
    },
  });

  const [setNotificationSeenMutation] = useMutation(SetNotificationSeen, {
    client: howlerClient.current,
  });

  const [sendAllReadMutation] = useMutation(SetAllNotificationsSeen, {
    client: howlerClient.current,
  });

  function setNotificationSeen(guid: string) {
    setNotificationSeenMutation({
      variables: {
        guid,
        ipAddress: '',
        userAgent: '',
      },
    });
  }
  function clear() {
    setNotifications([]);
  }

  function setNotificationsAllRead() {
    setNotifications((currentState) => {
      return [...currentState].map((notification) => {
        return { ...notification, isRead: true };
      });
    });
    if (notifications.filter((r) => r.id !== 0).length > 0) sendAllReadMutation();
  }

  function setNotificationsAllOld() {
    setNotifications((currentState) => {
      return [...currentState].map((notification) => {
        return { ...notification, isNew: false };
      });
    });
    if (notifications.filter((r) => r.id !== 0).length > 0) sendAllReadMutation();
  }

  const hasUnreadNotifications = notifications.some((notification) => {
    return !notification.isRead;
  });

  return (
    <Context.Provider
      value={{
        reminderNotification,
        hasUnreadNotifications,
        isLoading: notificationsLoading,
        notifications,
        refetch: () => {},
        setNotificationsAllOld,
        setNotificationsAllRead,
        setNotificationSeen,
        clear,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useNotifications() {
  return useContext(Context);
}
