import React, { ReactNode, createContext, useContext } from 'react';

import { Form as FormikForm, Formik, FormikValues } from 'formik';

import { FormErrors } from 'components/Shared/Forms/FormErrors';

export interface FormProps extends FormikValues {
  children: any;
  className?: string;
  id: string;
  onValuesChange?: (values: Record<string, any>) => void;
  showErrors?: boolean;
}

const FormContext = createContext<{ id: string }>({ id: null });

function Form({ children, id, className, onValuesChange, showErrors = true, ...formikProps }: FormProps) {
  return (
    // @ts-ignore
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Formik {...(formikProps as FormikValues)}>
      {(formik) => {
        // setValues(formik.values);
        return (
          <FormikForm className={className} id={id}>
            <FormContext.Provider value={{ id }}>
              {showErrors && <FormErrors />}

              {typeof children === 'function' ? children(formik) : children}
            </FormContext.Provider>
          </FormikForm>
        );
      }}
    </Formik>
  );
}

function useFormId() {
  return useContext(FormContext).id;
}

export { Form, useFormId };
