import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GELADA_API_URL,
});

const link = setContext((_, { headers }) => {
  return {
    ...headers,
    'Content-Type': 'application/graphql',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    custom_build_guid: process.env.REACT_APP_PROJECT_ID,
  };
});

const client = new ApolloClient({
  link: from([link, httpLink]),
  cache: new InMemoryCache(),
});

export { client };
