import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export type Endpoint = 'bonobo' | 'capuchin' | 'ekardo' | 'gelada' | 'howler' | 'muriqui' | 'vervet';

const endpoints = {
  bonobo: process.env.REACT_APP_BONOBO_API_URL,
  capuchin: process.env.REACT_APP_CAPUCHIN_API_URL,
  ekardo: process.env.REACT_APP_EKARDO_API_URL,
  gelada: process.env.REACT_APP_GELADA_API_URL,
  howler: process.env.REACT_APP_HOWLER_API_URL,
  muriqui: process.env.REACT_APP_MURIQUI_API_URL,
  vervet: process.env.REACT_APP_VERVET_API_URL,
} as Record<Endpoint, string>;

export function Client(endpoint) {
  const httpLink = createHttpLink({
    uri: endpoints[endpoint],
  });

  const link = setContext((_, { headers }) => {
    const updatedHeaders = {
      ...headers,
      'Content-Type': 'application/graphql',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    };

    if (endpoint === 'gelada') {
      updatedHeaders.custom_build_guid = process.env.REACT_APP_PROJECT_ID;
    }

    return updatedHeaders;
  });

  const client = new ApolloClient({
    link: from([link, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            contentPage: {
              merge: true,
            },
            contentSnippet: {
              merge: true,
            },
            contentSection: {
              merge: true,
            },
            userFlowStep: {
              merge: true,
            },
            userFlow: {
              merge: true,
            },
          },
        },
        Mutation: {
          fields: {
            userFlowStepTrack: {
              merge: true,
            },
          },
        },
      },
    }),
  });

  return client;
}
