import { createContext, useContext, useState } from 'react';

import { useHomeRoute } from 'utils/hooks/useHomeRoute';

export interface Crumb {
  route: string;
  title: string;
}

interface IBreadcrumbsContext {
  crumbs: Crumb[];
  setCrumbs: (crumbs: Crumb[]) => void;
}

const Context = createContext<IBreadcrumbsContext>({
  crumbs: [],
  setCrumbs: () => {},
});

export function BreadcrumbsProvider({ children }) {
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);

  const homeRoute = useHomeRoute();

  const homeCrumb: Crumb = {
    route: homeRoute,
    title: 'Home',
  };

  return (
    <Context.Provider
      value={{
        crumbs: [homeCrumb, ...crumbs],
        setCrumbs,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useBreadcrumbs() {
  return useContext(Context);
}
