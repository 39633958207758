import React from 'react';

import { Base, ButtonProps } from './Base';

function Button(props: ButtonProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading, react/destructuring-assignment
  return <Base component="button" disabled={props.isDisabled} {...props} />;
}

export { Button };
