import React, { useState } from 'react';

import cx from 'classnames';
import { useClickOutside } from 'utils/hooks/useClickOutside';

import { Breadcrumbs } from 'components/Shared/UI/Breadcrumbs';
import { Link } from 'components/Shared/UI/Link';

import { BaseLayout, BaseLayoutProps } from './Base';

interface NavItem {
  description?: string;
  id?: number;
  label?: string;
  link?: string;
  pages?: Array<NavItem>;
}

export const teacherNavList: Array<NavItem> = [
  {
    id: 1,
    label: 'Modules & planning',
    pages: [],
    link: '/teacher/modules',
  },
  {
    id: 2,
    label: 'Students',
    pages: [
      { label: 'Manage students', link: '/teacher/manage-students', description: 'Manage student account settings' },
      { label: 'Student progress', link: '/teacher/manage-students/progress', description: 'View student lesson and activity progress' },
      { label: 'Marking centre', link: '/teacher/manage-students/marking-center', description: 'Manage student marking' },
    ],
  },
  {
    id: 3,
    label: 'Teachers',
    pages: [
      { label: 'Manage teachers', link: '/teacher/manage-teachers', description: 'Review/manage registered teachers' },
      { label: 'Invite teachers', link: '/teacher/manage-teachers/invite', description: 'Invite new teachers' },
    ],
  },
];

export function Layout({ title, children, description, crumbs }: BaseLayoutProps) {
  const [isSubNavOpen, setIsSubNavOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState<NavItem>({});

  const subMenuRef = useClickOutside(isSubNavOpen, () => {
    setIsSubNavOpen(false);
    setActiveNavItem({});
  });

  const onNavClick = (item: NavItem) => {
    setIsSubNavOpen(true);

    setActiveNavItem((prevItem) => {
      if (prevItem.id === item.id) {
        setIsSubNavOpen(false);
        return {};
      }
      return item;
    });
  };

  return (
    <BaseLayout title={title} description={description} crumbs={crumbs}>
      <div className="l-position--relative" ref={subMenuRef}>
        <nav aria-label="Teacher" className="h-background--color-brand h-with-small-padding h-desktop-only">
          <ul className="l-flex l-container l-container--medium l-container pb-none pt-none">
            {teacherNavList.map((item) => {
              const isLink = item?.pages?.length === 0;

              if (isLink) {
                return (
                  <li className="mr-6xl l-vertical-centered" key={item.id}>
                    <Link className="opacity-80 hover-opacity-1" href={item.link}>
                      {item.label}
                    </Link>
                  </li>
                );
              }

              return (
                <li key={item?.id}>
                  <button
                    onClick={() => onNavClick(item)}
                    className={cx('mr-6xl hover-opacity-1 no-border', {
                      'opacity-1': item?.id === activeNavItem.id,
                      'opacity-80': item?.id !== activeNavItem.id,
                    })}
                    type="button"
                  >
                    {item.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
        {isSubNavOpen && (
          <div className="h-background--color-brand-secondary h-with-small-padding h-desktop-only c-header__submenu">
            <ul className="l-flex l-container l-container--medium l-container">
              {activeNavItem?.pages?.map((subLink) => (
                <li className="mr-6xl">
                  <Link href={subLink.link}>
                    <span className="h5 h-font--color-white inline-block mb-sm">{subLink.label}</span>
                    <p>{subLink.description}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <Breadcrumbs />
      <div
        className="l-position--relative"
        style={{
          width: '100%',
          minHeight: '32rem',
        }}
      >
        {children}
      </div>
    </BaseLayout>
  );
}
