import React, { ReactNode, ComponentProps } from 'react';

import cx from 'classnames';
import { ColorType } from 'consts/colors';

import { Icon } from 'components/Shared/UI/Icon';
import { IconTypes } from 'components/Shared/UI/Icons/Icons';
import { Link } from 'components/Shared/UI/Link';
import { LinkProps } from 'components/Shared/UI/Link/Link';
import { LoadingIcon } from 'components/Spinners/LoadingIcon';

type IconSize = 'sm' | 'md' | 'lg' | 'xl';

export interface ButtonProps extends ComponentProps<'button'> {
  children?: ReactNode;
  color?: 'primary' | 'secondary' | 'tertiary';
  disabledMessage?: string;
  eliptical?: boolean;
  filled?: boolean;
  hasAttention?: boolean;
  hasMinWidth?: boolean;
  icon?: IconTypes;
  iconPosition?: 'left' | 'right';
  iconSize?: IconSize;
  isActive?: boolean;
  isDisabled?: boolean;
  isNaked?: boolean;
  onClick?: () => void;
  rotateIcon?: '0' | '90' | '180' | '270';
  round?: boolean;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
}

// ignoring due to desired behavior. This can be a component with an icon prop, but no children
// @ts-ignore
export interface ButtonLinkProps extends LinkProps {
  children?: ReactNode;
  color?: 'primary' | 'secondary' | 'tertiary';
  disabledMessage?: string;
  eliptical?: boolean;
  filled?: boolean;
  hasAttention?: boolean;
  hasMinWidth?: boolean;
  icon?: IconTypes;
  iconPosition?: 'left' | 'right';
  iconSize?: IconSize;
  isActive?: boolean;
  isNaked?: boolean;
  rotateIcon?: '0' | '90' | '180' | '270';
  round?: boolean;
  target?: string;
  title?: string;
  isLoading?: boolean;
}

interface BaseButtonProps extends ButtonProps {
  component: 'button';
}

interface BaseButtonLinkProps extends ButtonLinkProps {
  component: 'a';
}

type BaseProps = BaseButtonProps | BaseButtonLinkProps;

function Base({
  isActive,
  filled = false,
  color = 'primary',
  icon,
  iconSize,
  children,
  component,
  eliptical,
  isDisabled,
  iconPosition = 'right',
  className,
  rotateIcon,
  round,
  isNaked,
  hasAttention,
  hasMinWidth,
  isLoading = false,
  disabledMessage,
  ...elementProps
}: BaseProps) {
  let Component: any = 'div';
  if (component === 'a') {
    Component = Link;
    // eslint-disable-next-line no-param-reassign
    (elementProps as LinkProps).isDisabled = isDisabled;
  } else if (component === 'button') {
    Component = 'button';
    // eslint-disable-next-line no-param-reassign
    (elementProps as ButtonProps).type = (elementProps as ButtonProps).type || 'button';
  }

  const iconOnly = Boolean(icon && !children);

  let iconColor: 'brand' | 'secondary' | 'tertiary' | ColorType = 'white';

  if (iconOnly && !filled) iconColor = 'brand';

  if (isNaked) {
    switch (color) {
      case 'primary': {
        iconColor = 'brand';
        break;
      }
      case 'secondary': {
        iconColor = 'secondary';
        break;
      }
      case 'tertiary': {
        iconColor = 'tertiary';
        break;
      }
      default:
        iconColor = 'brand';
    }
  }

  return (
    <Component
      title={isDisabled ? disabledMessage : undefined}
      className={cx(
        className,
        'l-possition--relative',
        'u-foreground',
        'c-button',
        'l-flex',
        'l-flex--center',
        `c-button--${color}`,
        Boolean(icon && children) && `c-button--has-icon-${iconPosition}`,
        {
          'c-button--has-icon': Boolean(icon),
          'c-button--icon-only': iconOnly,
          'c-button--is-disabled': isDisabled,
          'c-button--filled': filled,
          'c-button--outline': !filled,
          'c-button--eliptical': eliptical,
          'c-button--is-active': isActive,
          'c-button--round': round,
          'c-button--is-naked': isNaked,
          'h-no-padding': isNaked,
        },
      )}
      // The props defined above are used here, so it is in essence locked
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...elementProps}
    >
      {icon && iconPosition === 'left' && (
        <span className={cx('l-flex', 'l-flex--center', 'c-button__icon-container', `c-button__icon-container--${color}`)}>
          <Icon id={icon} color={iconColor} rotate={rotateIcon} size={isNaked ? 'md' : 'lg'} />
        </span>
      )}

      {children && <span className="c-button__content">{children}</span>}
      {icon && iconPosition === 'right' && (
        <span
          className={cx(
            'l-flex',
            'l-flex--center',
            'c-button__icon-container',
            `c-button__icon-container--${color}`,
            hasMinWidth && 'c-button__icon-container--has-min-width',
          )}
        >
          <LoadingIcon isLoading={isLoading} />
          {!isLoading && <Icon id={icon} color={iconColor} rotate={rotateIcon} size={iconSize || (isNaked ? 'md' : 'lg')} />}
        </span>
      )}
      {hasAttention && (
        <span
          style={{
            top: '0rem',
            right: '0rem',
          }}
          className="l-position--absolute ring-container"
        >
          <span className="ringring" />
          <span className="circle" />
        </span>
      )}
    </Component>
  );
}

export { Base };
