import { getRole } from './getRoleType';

export function getHomeRoute(token?: string, permissions?: any, hasMultipleSchool?: boolean) {
  if (!token) {
    return '/';
  }

  switch (getRole(permissions)) {
    case 'Admin':
    case 'Teacher':
      return hasMultipleSchool ? '/teacher/school-selection' : '/teacher';
    case 'Student':
      return '/student';
    case 'PendingTeacher':
      return '/teacher/pending';
    case null:
    default:
      return '/';
  }
}
