import React from 'react';

import cx from 'classnames';

export interface LineHorizontalSpacerProps {
  className?: string;
  fullWidth?: boolean;
}

export function LineHorizontalSpacer({ className, fullWidth }: LineHorizontalSpacerProps) {
  return (
    <span
      className={cx('c-line-horizontal-spacer', className, {
        'c-line-horizontal-spacer--is-full-width': fullWidth,
      })}
    />
  );
}
