import React, { ReactNode, useEffect, useState } from 'react';

import cx from 'classnames';
import { useClickOutside } from 'utils/hooks/useClickOutside';

import { Button } from 'components/Shared/Inputs/Button';

export interface PopoverProps {
  children: (props: { handleClick: () => void }) => ReactNode;
  className?: string;
  cta: (props: { handleClick: () => void; isOpen: boolean }) => ReactNode;
  horizontalAlign?: 'left' | 'right';
  onOpen?: () => void;
  verticalAlign?: 'top' | 'bottom';
  withCloseButton?: boolean;
}

export function Popover({
  children,
  className,
  cta,
  horizontalAlign = 'left',
  verticalAlign = 'bottom',
  withCloseButton = true,
  onOpen,
}: PopoverProps) {
  const [isOpen, setIsOpen] = useState(null);
  const ref = useClickOutside(isOpen, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen === true) {
      onOpen?.();
    }
  }, [isOpen]);

  return (
    <div className={`c-popover l-position--relative kanzi-speech ${className}`} ref={ref}>
      {cta({ handleClick: () => setIsOpen((val) => !val), isOpen })}
      <div
        className={cx('c-popover__wrapper', `c-popover__wrapper--align-${horizontalAlign}`, `c-popover__wrapper--align-${verticalAlign}`, {
          'c-popover__wrapper--is-open': isOpen,
        })}
      >
        <div className={cx('c-popover__content', 'h-background--color-white', 'h-with-padding')} aria-hidden={isOpen ? 'false' : 'true'}>
          {withCloseButton && (
            <Button
              className="c-popover__close-button"
              onClick={() => setIsOpen((val) => !val)}
              icon="cross"
              filled
              round
              color="secondary"
              aria-label="Close"
            />
          )}
          {typeof children === 'function' ? children({ handleClick: () => setIsOpen((val) => !val) }) : children}
        </div>
      </div>
    </div>
  );
}
