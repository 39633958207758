const modulesYearsAvailability = {
  alcohol: [7, 8],
  'alcohol-and-cannabis': [8, 9],
  'cannabis-and-psychostimulants': [9, 10, 11],
  'mental-health': [7, 8, 9, 10],
  'mdma-and-emerging-drugs': [10, 11],
};

export function isModuleAvailableForStudent(studentYear: number, groupCustomFields: any, moduleUrl: string): boolean {
  if (groupCustomFields !== null) {
    const customField = groupCustomFields.find((r) => r.customFieldId === 34);
    if (customField && customField.value) return true;
  }
  if (modulesYearsAvailability[moduleUrl]?.includes(studentYear)) return true;
}
