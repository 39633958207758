export function getContentGroupCompletion(contentGroup, mode, lastPage, userFlowStep) {
  if (!contentGroup) {
    return {
      completionPercentage: 0,
      lastViewedPage: null,
    };
  }
  let completionPercentage = userFlowStep?.userStatus === 'COMPLETED' ? 1 : 0;
  if(contentGroup.contentPages){
    completionPercentage= ([...contentGroup.contentPages]
          .filter((contentPage) => contentGroup !== 'ACTIVITY' || contentPage.mode.includes(mode))
          .sort((a, b) => a.sort - b.sort)
          .findIndex((page) => page.id === lastPage?.id) + 1 || 0) / contentGroup.contentPages.length;
        }

  return {
    ...contentGroup,
    completionPercentage,
    lastViewedPage: lastPage,
  };
}

export function getContentGroupCompletionBis(contentGroup, mode, lastPage, userFlowStep) {
  if (!contentGroup)
    return {
      lastViewedPage: null,
      completionPercentage: 0,
    };
  const completionPercentage = userFlowStep?.isCompleted
    ? 1
    : ([...contentGroup.contentPages]
        .filter((contentPage) => contentGroup !== 'ACTIVITY' || contentPage.mode.includes(mode))
        .sort((a, b) => a.sort - b.sort)
        .findIndex((page) => page.id === lastPage?.id) + 1 || 0) / contentGroup.contentPages.length;

  return {
    ...contentGroup,
    completionPercentage,
    lastViewedPage: lastPage,
  };
}
