import { createContext, useContext, useEffect, useState } from 'react';

import { client } from 'graphql/geladaClient';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { getHomeRoute } from 'utils/functions/getHomeRoute';
import { getItem, removeItem, setItem } from 'utils/functions/localStorage';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import { useQuery } from 'utils/hooks/useQuery';

import { getExpiry } from './Context.helpers';
import { IAuthorizationContext, IGroup, IUser } from './Context.interfaces';
import { getPermissions, getRefreshTokenQuery, getTempTokenQuery, refreshTokenQuery } from './Context.queries';

const Context = createContext<IAuthorizationContext>({
  getAccessToken: () => {},
  getTempToken: () => {},
  getUser: () => {},
  handleSignIn: () => {},
  handleSignOut: () => {},
  updateUser: () => {},
  isLoading: false,
  handleRefreshToken: () => {},
  tempToken: null,
  isTrial: false,
  isHandledByMatildaCentre: false,
  user: null,
  handleSetGroup: () => {},
  handleSetUnit: () => {},
  getPermissions: () => {},
});

export function AuthorizationProvider({ children }) {
  const { push, query } = useRouter();
  const [cookie, setCookie] = useCookies();

  const [user, setUser] = useState(getItem('user'));
  const [token, setTempToken] = useState(getItem('tempToken'));
  const [permissions, setPermissions] = useState(getItem('permissions'));
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const [isHandledByMatildaCentre, setIsHandledByMatildaCentre] = useState<boolean>(false);

  const [executeGetPermissions] = useLazyQuery(getPermissions, {
    anon: false,
    client,
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const {
        membership: { getPermission },
      } = response;

      setPermissions(getPermission);
      setItem('permissions', getPermission);
    },
  });

  const [executeGetTempToken, { loading: isGetTempTokenLoading }] = useLazyQuery(getTempTokenQuery, {
    anon: true,
    client,
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const { getTemporarySession: tempToken } = response;
      const tokenExpiry = getExpiry(tempToken);

      setItem('tempToken', tempToken, tokenExpiry);
      setCookie('tempToken', tempToken, { expires: tokenExpiry });
      setTempToken(tempToken);
    },
  });

  const [executeRefreshToken, { loading: isRefreshTokenLoading }] = useLazyQuery(refreshTokenQuery, {
    client,
    onCompleted(response) {
      const {
        refreshToken: { accessToken, refreshToken },
      } = response;

      const accessExp = getExpiry(accessToken);

      setItem('accessToken', accessToken, accessExp);
      const refreshExp = getExpiry(refreshToken);
      setItem('refreshToken', refreshToken, refreshExp);

      setCookie('refreshToken', refreshToken, { expires: refreshExp });
      setCookie('accessToken', accessToken, { expires: accessExp });
      // We reload the page to ensure that the page is loaded correctly
      window.location.href = window.location.href;
    },
  });

  const [executeGetRefreshTokenQuery, { loading: isGetRefreshTokenLoading }] = useLazyQuery(getRefreshTokenQuery, {
    client,
    onCompleted(response) {
      const {
        user: {
          getLoggedUser: { customFields },
          refreshToken: { accessToken, refreshToken, user: returnedUser },
        },
      } = response;

      const { firstname = '', lastname = '', memberships } = returnedUser;

      const accessExp = getExpiry(accessToken);
      setItem('accessToken', accessToken, accessExp);

      const refreshExp = getExpiry(refreshToken);
      setItem('refreshToken', refreshToken, refreshExp);
      setCookie('refreshToken', refreshToken, { expires: refreshExp });
      setCookie('accessToken', accessToken, { expires: accessExp });
      const currentMembership = memberships.find(({ group, project }) => {
        return (
          // Ignore memberships to other projects. Only really an issue for admin
          project.guid === process.env.REACT_APP_PROJECT_ID &&
          // This project requires groups, so just filter out ones with no group
          group &&
          // Users who register the school are automatically placed in the default group. This should be filtered out
          group.id !== Number(process.env.REACT_APP_PROJECT_DEFAULT_GROUP)
        );
      });

      const avatar = customFields.find(({ customFieldId }) => customFieldId === 6)?.number;
      const postcode = customFields.find(({ customFieldId }) => customFieldId === 9)?.text;
      const year = customFields.find(({ customFieldId }) => customFieldId === 3)?.number;

      const updatedUser = {
        ...returnedUser,
        accessExp,
        avatar,
        group: {
          ...currentMembership?.group,
          code: currentMembership?.group.smartCodes[0]?.code,
          smartCodes: currentMembership?.group.smartCodes,
        },
        initials: `${firstname?.split('')[0] ?? 'O'}${lastname?.split('')[0] ?? 'F'}` || 'OF',
        postcode,
        unit: currentMembership?.unit,
        year,
      };

      setItem('user', updatedUser, accessExp);
      setUser(updatedUser);

      const {
        group: { customFields: cf },
      } = updatedUser;

      if (!cf) {
        setIsTrial(false);
        setIsHandledByMatildaCentre(false);
      } else {
        const customFieldMatildaCentre = cf.find((r) => r.customFieldId === 35);
        const customFieldIsTrial = cf.find((r) => r.customFieldId === 26);

        if (customFieldMatildaCentre) {
          setIsHandledByMatildaCentre(customFieldMatildaCentre.value);
        } else {
          setIsHandledByMatildaCentre(false);
        }

        if (!customFieldIsTrial) {
          setIsTrial(false);
        } else {
          setIsTrial(customFieldIsTrial.value);
        }
      }

      push(query.prepath?.toString() || getHomeRoute(accessToken, permissions, updatedUser.memberships.length > 1));
    },
  });

  function handleRefreshToken() {
    executeRefreshToken({
      variables: {
        refreshToken: getItem('refreshToken'),
      },
    });
  }

  function handleSignIn({ refreshToken, accessToken }) {
    console.log('handleSignIn', { accessToken, refreshToken });
    executeGetPermissions({
      context: {
        headers: {
          authorization: accessToken,
        },
      },
    });
    executeGetRefreshTokenQuery({
      context: {
        headers: {
          authorization: accessToken,
        },
      },
      variables: {
        refreshToken,
      },
    });
  }

  function handleSetGroup(group: IGroup) {
    setUser((current) => {
      current.group = group;
      setItem('user', current, new Date(current.accessExp));
      return current;
    });
  }

  function handleSetUnit(unit: any) {
    setUser((current) => {
      current.unit = unit;
      setItem('user', current, new Date(current.accessExp));
      return current;
    });
  }

  function handleSignOut() {
    removeItem('accessToken');
    removeItem('group');
    removeItem('refreshToken');
    removeItem('user');
    removeItem('permissions');
    push('/');
  }

  function updateUser(newUser) {
    const exp = new Date(user?.accessExp);
    setItem('user', newUser, exp);
    setUser(newUser);
  }

  useEffect(() => {
    if (!getItem('tempToken')) {
      executeGetTempToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) return;
    const {
      group: { customFields },
    } = user;
    if (!customFields) return;
    const customFieldIsTrial = customFields.find((r) => r.customFieldId === 26);
    if (customFieldIsTrial) {
      setIsTrial(customFieldIsTrial.value);
    }
    const customFieldIsHandledByMatildaCentre = customFields.find((r) => r.customFieldId === 35);
    if (customFieldIsHandledByMatildaCentre) {
      setIsHandledByMatildaCentre(customFieldIsHandledByMatildaCentre.value);
    }
  }, [user]);

  return (
    <Context.Provider
      value={
        {
          isTrial,
          isHandledByMatildaCentre,
          getAccessToken: () => getItem('accessToken'),
          getPermissions: () => getItem('permissions'),
          getTempToken: () => getItem('tempToken'),
          getUser: () => getItem('user'),
          handleSignIn,
          handleRefreshToken,
          handleSignOut,
          handleSetGroup,
          handleSetUnit,
          isLoading: isGetRefreshTokenLoading || isGetTempTokenLoading,
          tempToken: getItem('tempToken'),
          updateUser,
          user,
        } as IAuthorizationContext
      }
    >
      {children}
    </Context.Provider>
  );
}
export function useIsHandledByMatildaCentre() {
  return useContext(Context).isHandledByMatildaCentre;
}

export function useIsTrial() {
  return useContext(Context).isTrial;
}

export function usePermissions() {
  return useContext(Context).getPermissions();
}

export function useAccessToken() {
  return useContext(Context).getAccessToken() || null;
}

export function useRefreshToken() {
  return useContext(Context).handleRefreshToken;
}
export function useUser() {
  const context = useContext(Context);
  return context.user || context.getUser() || ({ credential: {} } as IUser);
}

export function useUpdateUser() {
  return useContext(Context).updateUser;
}

export function useGroup() {
  return (useContext(Context).getUser() || {})?.group || ({} as IGroup);
}

export function useSetUnit() {
  return useContext(Context).handleSetUnit;
}
export function useSetGroup() {
  return useContext(Context).handleSetGroup;
}

export function useHandleSignIn() {
  return useContext(Context).handleSignIn;
}

export function useHandleSignOut() {
  return useContext(Context).handleSignOut;
}

export function useIsAuthLoading() {
  return useContext(Context).isLoading;
}

export function useTempToken() {
  return useContext(Context).tempToken;
}

export function useIsAuthenticated() {
  return Boolean(useContext(Context).getAccessToken());
}
