export interface LoadingIconPropType {
  isLoading?: boolean;
}

export function LoadingIcon({ isLoading = true }: LoadingIconPropType) {
  if (!isLoading) {
    return null;
  }

  return <img className="l-flex l-position-h-center" alt="Loading data." src="/images/loading.svg" />;
}
