import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

export function useIsCurrentRoute({ href, as }: any) {
  const [isCurrentPath, setIsCurrentPath] = useState(false);

  const { asPath } = useRouter();

  useEffect(() => {
    setIsCurrentPath(asPath === (typeof href === 'string' ? href : href?.href) || asPath === (typeof as === 'string' ? as : as?.href));
  }, [asPath]);

  return isCurrentPath;
}
