import React, { useEffect, useState } from 'react';

import { avatarImageMap } from 'consts/avatar';
import { useIsAuthenticated, useUser, useHandleSignOut, useAccessToken, usePermissions, useGroup } from 'contexts/Authorization/Context';
import { getRole } from 'utils/functions/getRoleType';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import { Button, ButtonLink } from 'components/Shared/Inputs/Button';
import { Link } from 'components/Shared/UI/Link';

import { LoginPopover } from './LoginPopover';
import { NotificationPopover } from './NotificationPopover';

interface UserSectionProps {
  className?: string;
  onClick?: () => void;
}

export function UserSection({ className, onClick = () => {} }: UserSectionProps) {
  const [showSignIn, setShowSignedIn] = useState(false);
  const signOut = useHandleSignOut();
  const isAuthenticated = useIsAuthenticated();
  const user = useUser();

  const { isTablet, isSmallTablet, isDesktop } = useWindowSize();

  useEffect(() => {
    setShowSignedIn(isAuthenticated);
  });

  const group = useGroup();
  const roleType = getRole(usePermissions(), group.id);
  const isStudent = roleType === 'Student';
  const hasMultipleSchool = user?.memberships?.length > 1;
  return (
    <div className={`l-flex l-flex--justify-end ${className}`}>
      {showSignIn ? (
        <nav className="c-header-user-section" aria-label="User">
          <ul className="l-flex l-full-width l-flex--end">
            {isDesktop || (isTablet && !isSmallTablet) ? (
              <li className="l-flex__item--row c-header-user-section__">
                <NotificationPopover />
              </li>
            ) : null}
            <li className="l-flex__item--row c-header-user-section__profile">
              {user?.avatar ? (
                <Link href="/user/profile" onClick={onClick}>
                  <img
                    className="l-flex__item--row"
                    style={{ width: '4rem' }}
                    src={`/images/avatars/${avatarImageMap[Number(user.avatar)]?.toLowerCase()}.webp`}
                    alt=""
                  />
                </Link>
              ) : (
                <ButtonLink
                  className="c-typography--fontsize-large kanzi-not-translate border-tertiary"
                  href="/user/profile"
                  filled
                  round
                  color="tertiary"
                  title="View and/or edit your profile."
                >
                  <span>{user?.initials}</span>
                  <span className="h-sr-only">User profile.</span>
                </ButtonLink>
              )}
            </li>
            <li className="l-flex__item--row c-header-user-section__modules">
              {isStudent ? (
                <ButtonLink className="c-button-link-header" href={`${isStudent ? '/student' : '/teacher'}`} color="primary">
                  Your modules
                </ButtonLink>
              ) : (
                <ButtonLink className="c-button-link-header" href={`${isStudent ? '/student' : '/teacher'}`} color="primary">
                  Dashboard
                </ButtonLink>
              )}
            </li>
            {hasMultipleSchool && (
              <li className="l-flex__item--row c-header-user-section__switch-school">
                <ButtonLink className="c-button-link-header" href="/teacher/school-selection" color="primary">
                  Switch school
                </ButtonLink>
              </li>
            )}
            <li className="l-flex__item--row c-header-user-section__logout">
              <Button
                color="secondary"
                onClick={() => {
                  signOut();
                  onClick();
                }}
              >
                Logout
              </Button>
            </li>
          </ul>
        </nav>
      ) : (
        <nav className="c-header-anonymous" aria-label="Authentication">
          <ul className="l-flex l-full-width l-flex--end">
            <li className="l-flex__item--row">
              <LoginPopover onClick={onClick} />
            </li>
            <li className="l-flex__item--row registration">
              <ButtonLink href="/register" onClick={onClick}>
                Register
              </ButtonLink>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}
