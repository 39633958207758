function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function uploadFileToS3(file, s3Key) {
  const Http = new XMLHttpRequest();
  Http.open('POST', process.env.REACT_APP_FILE_UPLOAD_API_URL);

  toBase64(file).then((base64: string) => {
    return Http.send(
      JSON.stringify({
        Base64: base64.replace(/.*base64,/, ''),
        S3Key: s3Key,
        IsPublic: true,
        ContentType: file.type,
        Bucket: 'ekardo',
      }),
    );
  });
}
