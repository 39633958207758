export function handleApiError(error, setter): void {
  const errorMessages = getErrorMessages(error);

  errorMessages.map((errorMessage) => console.error(errorMessage));
  setter(errorMessages);
}

export function getErrorMessages(error): string[] {
  if (!error) {
    return [];
  }

  const { message, networkError } = error;
  const { result } = networkError || {};
  const { errors = [] } = result || {};

  return errors.length ? errors.map((err) => err.message) : [message];
}
