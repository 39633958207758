import { gql } from '@apollo/client';

const accessFragment = gql`
  fragment userInformationFragment on LoginGraphType {
    accessToken: token
    refreshToken
    user {
      customFields {
        customFieldId
        ... on CustomFieldResponseDateGraphType {
          dateTime
        }
        ... on CustomFieldResponseNumberGraphType {
          number
        }
        ... on CustomFieldResponseTextGraphType {
          text
        }
        ... on CustomFieldResponseBooleanGraphType {
          value
        }
      }
      communityName
      credential {
        email
        phoneNumber
      }
      firstname
      id
      lastname
      memberships(take: 10) {
        id
        group {
          contactEmail
          id
          name
          customFields {
            customFieldId
            ... on CustomFieldResponseBooleanGraphType {
              value
            }
          }
          smartCodes {
            code
            userType {
              name
            }
          }
          status
        }
        project {
          guid
        }
        unit {
          id
          name
        }
      }
    }
  }
`;

const getRefreshTokenQuery = gql`
  query getRefreshTokenQuery($refreshToken: String!) {
    user {
      getLoggedUser {
        customFields {
          customFieldId
          id
          ... on CustomFieldResponseNumberGraphType {
            number
          }
        }
      }
      refreshToken(refreshToken: $refreshToken) {
        ...userInformationFragment
      }
    }
  }
  ${accessFragment}
`;

const refreshTokenQuery = gql`
  query($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken: token
      refreshToken
    }
  }
`;

const getTempTokenQuery = gql`
  query getTempTokenQuery {
    getTemporarySession
  }
`;

const getPermissions = gql`
  query getPermissions {
    membership {
      getPermission {
        _c {
          _c {
            id
            p
            t
          }
          id
          p
          t
        }
        id
        p
        t
      }
    }
  }
`;
export { accessFragment, getRefreshTokenQuery, getTempTokenQuery, getPermissions, refreshTokenQuery };
