import { useState, useEffect } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [fontSize, setFontSize] = useState(18);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    setFontSize(parseFloat(getComputedStyle(document.documentElement).fontSize));

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const windowSizeRem = {
    width: windowSize.width / fontSize,
    height: windowSize.height / fontSize,
  };

  return {
    fontSize,
    windowSize,
    windowSizeRem,
    isMobile: windowSizeRem.width <= 35.999,
    isSmallTablet: windowSizeRem.width >= 36 && windowSizeRem.width <= 48,
    isTablet: windowSizeRem.width >= 36 && windowSizeRem.width <= 74.999,
    isLargeTablet: windowSizeRem.width >= 63.999 && windowSizeRem.width <= 75,
    isDesktop: windowSizeRem.width >= 75,
  };
}
