import decode from 'jwt-decode';

function isTokenExpired(token) {
  const expirationDate = getExpiry(token);
  if (!expirationDate || expirationDate < new Date()) {
    return true;
  }
  return false;
}

function getExpiry(token):Date {
  if (!token) {
    return null;
  }

  return new Date((decode(token) as unknown as any).exp * 1000);
}

export { getExpiry, isTokenExpired };
