import React, { useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { useHandleSignIn } from 'contexts/Authorization/Context';
import { accessFragment } from 'contexts/Authorization/Context.queries';
import { useNotifications } from 'contexts/Notifications/Context';
import { client } from 'graphql/geladaClient';
import { isTrialExpired } from 'utils/functions';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';
import * as Yup from 'yup';

import { Button } from 'components/Shared/Inputs/Button';
import { Form } from 'components/Shared/Forms/Form';
import { InputField } from 'components/Shared/UI/InputField';
import { LineHorizontalSpacer } from 'components/Shared/UI/LineHorizontalSpacer';
import { Link } from 'components/Shared/UI/Link';
import { Popover } from 'components/Shared/UI/Popover';

const signInQuery = gql`
  query SignInQuery($password: String!, $email: String!) {
    login(login: $email, password: $password) {
      ...userInformationFragment
    }
  }
  ${accessFragment}
`;

interface UserPopoverProps {
  onClick: () => void;
}

export function LoginPopover({ onClick }: UserPopoverProps) {
  const closeRef = useRef<() => void>();
  const signIn = useHandleSignIn();

  const { clear } = useNotifications();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [sendSignInQuery, { error, loading }] = useLazyQuery(signInQuery, {
    client,
    anon: true,
    fetchPolicy: 'no-cache',
    onCompleted({ login }) {
      if (!isTrialExpired(login)) {
        setErrorMessage('Your 2 week trial has expired, please contact OurFutures to upgrade your account or extend your trial access.');
        return;
      }
      signIn(login);

      onClick();
      closeRef.current();
    },
  });

  return (
    <Popover
      cta={({ handleClick, isOpen }) => (
        <Button onClick={handleClick} color="secondary">
          {isOpen ? 'Close' : 'Login'}
        </Button>
      )}
      horizontalAlign="right"
    >
      {({ handleClick }) => {
        closeRef.current = handleClick;
        function handleLogin({ email, password }) {
          clear();
          setErrorMessage(undefined);
          sendSignInQuery({
            variables: { email, password },
          });
        }

        return (
          <div style={{ width: '18rem', maxWidth: '100%' }}>
            <h1>Login</h1>
            <Form
              id="login"
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().label('Email').email().required('is required'),
                password: Yup.string().label('Password').required('is required'),
              })}
              onSubmit={handleLogin}
            >
              <InputField
                name="email"
                className="l-full-width h-no-margin"
                type="email"
                label="Email address"
                autoComplete="email"
                required
                altBackground
              />
              <InputField
                name="password"
                className="l-full-width h-no-margin"
                type="password"
                label="Password"
                autoComplete="current-password"
                required
                altBackground
              />
              <div className="l-flex l-flex--justify-end c-typography--light">
                <Link onClick={handleClick} className="l-flex__item--row" href="/user/forgot-password">
                  Forgot password
                </Link>
                <Link onClick={handleClick} className="l-flex__item--row registration" href="/register">
                  Register
                </Link>
              </div>
              <div className="l-flex h-with-y-margin">
                <LineHorizontalSpacer />
                <Button type="submit" isDisabled={loading}>
                  Login
                </Button>
              </div>
              {errorMessage && (
                <p role="alert" className="c-form-input-error">
                  {errorMessage}
                </p>
              )}
              {error && (
                <p role="alert" className="c-form-input-error">
                  {(
                    error.networkError?.result?.errors?.[0]?.message ||
                    error.graphQLErrors?.[0]?.message ||
                    'Somthing went wrong. Please try again.'
                  ).replace(/A login/, 'Email')}
                  .
                </p>
              )}
            </Form>
          </div>
        );
      }}
    </Popover>
  );
}
