import { setWithExpiry, getWithExpiry } from 'utils/functions/storeWithExpiry';

function localStorageTryCatch(funcName: string, key?: string, value?: string) {
  if (typeof window === 'undefined') {
    return null;
  }

  try {
    return window.localStorage[funcName](key, value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('localStorage is not supported: ', error);
    return null;
  }
}

function removeItem(key: string) {
  return localStorageTryCatch('removeItem', key);
}

function clear() {
  return localStorageTryCatch('clear');
}

function setItem(key: string, value: unknown, expiry?: Date) {
  return setWithExpiry((parsedValue) => localStorageTryCatch('setItem', key, parsedValue), value, expiry);
}

function getItem(key: string) {
  return getWithExpiry(() => removeItem(key), localStorageTryCatch('getItem', key));
}

export { removeItem, setItem, getItem, clear };
